import React from 'react';
import { Box, DownChevronIcon, Menu } from '@ui/components/core';
import { Text } from '../../typography';
import { SortMenu as SortMenuStyles } from '@ui/components/styles';
import { useRouter } from 'next/router';
import { Button } from '@ui/components/shared';

type SortMenuProps = {
  sort: string;
  sortingOptions: any[];
  setSort?: (value: string) => void;
};

const SortMenu = ({ sort, sortingOptions, setSort }: SortMenuProps): React.ReactElement => {
  const menu = SortMenuStyles();
  const router = useRouter();

  return (
    <Menu position="bottom-end" offset={0} classNames={menu.classes}>
      <Menu.Target>
        <Box className={menu.classes.target}>
          <Button
            className="w-full h-9 uppercase no-underline pl-4 pr-3 text-[11px] md:text-[11px] !justify-center"
            variant="unstyled"
            rightIcon={<DownChevronIcon height={16} width={16} className="ml-2.5" />}
          >
            {sortingOptions.find(({ field, direction }) => `${field}=${direction}` === sort)?.label || 'Sort'}
          </Button>
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        {sortingOptions.map((sortingOption) => (
          <Menu.Item
            key={`${sortingOption.label}-sort-menu-item`}
            onClick={() => {
              if (setSort) {
                return setSort(`${sortingOption.field}=${sortingOption.direction}`);
              }
              router.query.sort = `${sortingOption.field}=${sortingOption.direction}`;
              router.push(router);
            }}
          >
            <Text tt="capitalize">{sortingOption.label}</Text>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default SortMenu;
