import { createStyles } from '@mantine/core';

const SortMenu = createStyles(() => ({
  target: {
    '& button': {
      paddingLeft: '1rem',
      padingRight: '1rem',
      fontSize: '11px',
    },

    '& svg': {
      transition: 'all 100ms',
    },

    '&[aria-expanded=true] svg': {
      transform: 'rotate(180deg)',
    },
  },

  dropdown: {
    border: '0.0625rem solid black',
    borderRadius: '0.125rem',
  },

  item: {
    '&[data-hovered]': {
      background: '#fff',
    },

    '&[data-hovered] .mantine-Text-root': {
      textDecoration: 'underline',
      textUnderlineOffset: '6px',
    },
  },
}));

export default SortMenu;
