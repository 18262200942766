export const ColorSwatchMappings = {
  'beige/cream': '#F4E7D9',
  black: '#000000',
  blue: '#7191F7',
  bronze: "url('https://cdn.shopify.com/s/files/1/0310/0436/0837/files/goldbronze.jpg?v=1715052879') center",
  brown: '#946A3F',
  clear: '#FFFFFF',
  denim: "url('https://cdn.shopify.com/s/files/1/0310/0436/0837/files/denim.jpg?v=1715052777') center",
  fuchsia: '#C34288',
  'gold/black': "url('https://cdn.shopify.com/s/files/1/0310/0436/0837/files/goldbronze.jpg?v=1715052879') center",
  'gold/bronze': "url('https://cdn.shopify.com/s/files/1/0310/0436/0837/files/goldbronze.jpg?v=1715052879') center",
  'gold/clear': '#E8CCAA',
  'gold/rose': '#B76E79',
  green: '#CBDAAE',
  grey: '#D9D9D9',
  ivy: '#4B5D36',
  'multi/pattern': "url('https://cdn.shopify.com/s/files/1/0310/0436/0837/files/multipattern.jpg?v=1715053008') center",
  nude: '#E3BC9A',
  orange: '#DB7632',
  peach: '#F9F4DA',
  pink: '#F7CCD4',
  purple: '#DACBFB',
  red: '#B1441D',
  rust: '#B7410E',
  silver: "url('https://cdn.shopify.com/s/files/1/0310/0436/0837/files/silver.jpg?v=1715053031') center",
  white: '#FFFFFF',
  yellow: '#FEF9AD',
  DEFAULT: '#FFFFFF',
};
