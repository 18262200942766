export const filtersToUri = (filters: string[]) => [...filters].sort().join('+').replace(/\//g, '%2F');

const CollectionFilterSequence = [
  'size',
  'style',
  'color',
  'price',
  'length',
  'season',
  'sleeves',
  'trend',
  'occasion',
  'brand',
];

export const sortFacets = (facets: any[]) => {
  if (facets.length === 0) {
    return [];
  }

  const [head, ...remainder] = facets;

  remainder.sort((a, b) => {
    const a1 = CollectionFilterSequence.indexOf(a.label.toLowerCase());
    const b1 = CollectionFilterSequence.indexOf(b.label.toLowerCase());
    return (a1 > -1 ? a1 : Infinity) - (b1 > -1 ? b1 : Infinity);
  });

  return [head, ...remainder];
};

export const FacetModel = (facet: any) => ({
  ...facet,
  values: facet.values.map((facetValue: any) => ({
    ...facetValue,
    formattedLabel:
      facet.label.toUpperCase() !== 'PRICE'
        ? facetValue.label.replace(/-/g, ' ')
        : facetValue.label,
    formattedValue:
      facet.label.toLowerCase() === 'price'
        ? `${facet.label.toLowerCase()}_low_${facetValue.low}_high_${facetValue.high}`
        : `${facet.label.toLowerCase()}_${facetValue.value.replaceAll(/\//g, '%2F')}`,
  })),
});

export const getBreadcrumbs = (collection: string, filters: string[]) => {
  const links = [{ title: collection, href: `/collections/${collection}/` }];
  if (collection === 'sale') {
    links.push({
      title: `WOMEN'S CLOTHING SALE`,
      href: `/collections/sale/`,
    });
    return links;
  }
  const categoryFromFilter = filters.find((filter) => filter.startsWith('category_'));
  if (categoryFromFilter && categoryFromFilter !== `category_${collection}`) {
    links.push({
      title: categoryFromFilter.split('_').slice(1).join(' '),
      href: `/collections/${collection}/${filtersToUri(filters)}/`,
    });
  } else {
    links.push({
      title: `All ${collection}`,
      href: `/collections/${collection}/`,
    });
  }

  return links;
};

export const getTitle = (collection: string, filters: string[]) => {
  const categoryFromFilter = filters.find((filter) => filter.startsWith('category_'));

  if (categoryFromFilter) {
    const formattedCategoryTitle = categoryFromFilter.split('_').slice(1).join(' ').replaceAll('-', ' ');
    return collection === 'new' ? `New ${formattedCategoryTitle}` : formattedCategoryTitle;
  } else {
    return collection.replaceAll('-', ' ');
  }
};
