import React from 'react';
import { ActionIcon, CloseIcon, Drawer } from '../../../core';
import Toggle from '../../toggles/Toggle/Toggle';
import { useRouter } from 'next/router';

const slideRight = {
  in: { transform: 'translateX(0)' },
  out: { transform: 'translateX(-100%)' },
  transitionProperty: 'transform',
};

type CollectionFilterDrawerProps = {
  opened: boolean;
  onClose: () => void;
  sort: string;
  sortingOptions: any[];
  setSort?: (value: string) => void;
};

const CollectionSortDrawer = ({ opened, onClose, sort, sortingOptions, setSort }: CollectionFilterDrawerProps) => {
  const router = useRouter();
  const header = (
    <div className="w-full flex items-center justify-between px-4">
      <span>SORT</span>
      <ActionIcon onClick={onClose}>
        <CloseIcon height={16} width={16} />
      </ActionIcon>
    </div>
  );

  const body = (
    <div className="space-y-5 mt-7">
      {sortingOptions.map((sortingOption) => (
        <div key={sortingOption.label}>
          <Toggle
            className="text-[13px] tracking-[0.39px]"
            pressed={`${sortingOption.field}=${sortingOption.direction}` === sort}
            value={`${sortingOption.field}=${sortingOption.direction}`}
            onPressedChange={() => {
              if (setSort) {
                return setSort(`${sortingOption.field}=${sortingOption.direction}`);
              }
              router.query.sort = `${sortingOption.field}=${sortingOption.direction}`;
              router.push(router);
              onClose();
            }}
          >
            {sortingOption.label}
          </Toggle>
        </div>
      ))}
    </div>
  );

  return (
    <Drawer
      header={header}
      body={body}
      opened={opened}
      onClose={onClose}
      transitionProps={{
        duration: 250,
        timingFunction: 'ease-in-out',
        transition: slideRight,
      }}
      className="block md:hidden"
      classNames={{
        content: 'flex flex-col flex-nowrap shadow-[1px_0_0_0_rgb(0_0_0/0.5)]',
        title: 'relative flex items-center justify-center text-center h-[48px] w-full text-[13px] uppercase',
        header: 'p-0 border-b border-[#f5f5f5]',
        body: 'flex-1 px-4 py-0 bg-white overflow-y-scroll overflow-x-hidden',
      }}
    />
  );
};

export default CollectionSortDrawer;
