import React from 'react';
import { Pagination, PaginationProps } from '../../../core';

const SimplePagination = (props: PaginationProps): React.ReactElement => (
  <Pagination
    {...props}
    spacing="0"
    styles={(theme) => ({
      control: {
        border: 'none',
        fontSize: '13px',
        [theme.fn.largerThan('md')]: {
          fontSize: '11px',
        },
        '&[data-active]': {
          background: 'none',
          fontWeight: 700,
          color: '#ECB8CF',
          ':hover': {
            color: theme.black,
          },
        },
      },
    })}
  />
);

export default SimplePagination;
