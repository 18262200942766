import React from 'react';
import { Box, BoxProps } from '../../../core';
import { ColorSwatchMappings } from '../../../../types/mappings/ColorSwatchMappings.type';

type ColorSwatchProps = BoxProps & {
  color: string;
  withBorder?: boolean;
};

const ColorSwatch = ({ color, withBorder, ...props }: ColorSwatchProps): React.ReactElement => (
  <Box
    {...props}
    w={props.w || '1rem'}
    h={props.h || '1rem'}
    sx={{
      borderRadius: '9999px',
      background: ColorSwatchMappings[color as keyof typeof ColorSwatchMappings] || ColorSwatchMappings.DEFAULT,
      border: withBorder ? '1px solid #000' : 'none',
    }}
  />
);

ColorSwatch.defaultProps = {
  withBorder: undefined,
};

export default ColorSwatch;
