import React from 'react';
import { Group } from '../../../core';
import FilterMenu from '../../menus/FilterMenu/FilterMenu';
import { getFacetsMapping } from '@ui/utils/facets';
import { Facet } from '@ui/helpers/filter';

type FilterMenuGroupProps = {
  facets: Array<Facet>;
  filters?: Array<string>;
  setFilters?: (value: string[]) => void;
};

const FilterMenuGroup = ({ facets, setFilters }: FilterMenuGroupProps): React.ReactElement => {
  return (
    <Group spacing="1rem" sx={{ '& > *': { flex: '1 1 0% !important' } }}>
      {getFacetsMapping(facets).map((facet, i) => (
        <FilterMenu
          key={facet.label}
          position={i / facets.length < 0.6 ? 'bottom-start' : 'bottom-end'}
          width={facet.values.length > 6 ? '54rem' : facet.values.length > 3 ? '20rem' : '12rem'}
          colSpan={facet.values.length > 6 ? 2 : facet.values.length > 3 ? 6 : 12}
          facet={facet}
          setFilters={setFilters}
        />
      ))}
    </Group>
  );
};

export default FilterMenuGroup;
